import $ from 'jquery';

// vue and vue components
import Vue from 'vue';
import Vuex from 'vuex';
import VeeValidate from 'vee-validate';
import Multiselect from 'vue-multiselect';
import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import CripVueLoading from 'crip-vue-loading';
import VModal from 'vue-js-modal';

// import VueLoading from 'vue-loading-overlay';
import VueGoodTablePlugin from 'vue-good-table';

import VueAwesomeComponents from 'vue-aw-components/src/plugin';
import { FormGenerator, notificationsMixin } from 'vue-aw-components';

// import en from 'vue-aw-components/src/translations/en.json';
import fr from 'vue-aw-components/src/translations/fr.json';
import en from 'vue-aw-components/src/translations/en.json';

// External libraries
import qs from 'qs';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr';
import _ from 'lodash';
import socketIOClient from 'socket.io-client';
import sailsIOClient from 'sails.io.js';

import 'chartist-plugin-pointlabels';

// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main';
import 'bootstrap/dist/js/bootstrap.bundle.min';
// / import 'eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min';

// router setup and local elements setup
import config from './config';
import store from './stores';

import * as filters from './filters/filters';
import App from './App.vue';


const isProduction = process.env.NODE_ENV === 'production';
// plugin setup
Vue.use(VeeValidate, {
  inject: false,
});
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(LightBootstrap);
const mixin = notificationsMixin;

// Vue.mixin(mixin);
// Vue.use(VueLoading);
Vue.use(VueI18n);

Vue.use(VModal);

Vue.use(FormGenerator, {
  fields: _.values(FormGenerator.fieldsLoader),
});
Vue.use(VueGoodTablePlugin);

Vue.use(require('vue-chartist'));

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});


Vue.component('multiselect', Multiselect);
Vue.component('vue-form-generator', FormGenerator, []);

// Vue.component('v-select', vSelect);
//
// Vue.use(Notify, {type: 'primary', timeout: 2000})

// global variables
window.App = {
  locale: config.defaultLocale || 'en',
};
window.$ = $;
window.jQuery = $;
if (!isProduction) {
  window._ = _;
  window.moment = moment;
}

let vInstance;

Vue.config.productionTip = isProduction;
axios.defaults.withCredentials = process.env.NODE_ENV === 'production';

Vue.prototype.$http = axios.create({
  baseURL: config.apiUrl,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem(`${config.appName}_token`)}`,
  },
});

Vue.use(CripVueLoading, { axios: Vue.prototype.$http });

Vue.prototype.$http.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response && error.response.status === 401) {
      /*
        swal({
          title: "Session Expired",
          text: "Your session has expired. Would you like to be redirected to the login page?",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes",
          closeOnConfirm: false
        }, function(){
          window.location = '/login';
        });
        */
      if (
        error.response
        && error.response.config
        && error.response.config.url
        && (error.response.config.url.indexOf('/login') === -1
          || error.response.config.url.indexOf('/register') === -1)
      ) {
        console.warn('401 => Logging out the USER');
        store.dispatch('logout');
        if (vInstance.$router) {
          vInstance.$router.push('/login');
        }
        // v.$notify("Your session has expired");
      }
    }
    return Promise.reject(error);
  },
);

Vue.use(VueAwesomeComponents, {
  default: {
    props: {
      primaryKey: { type: String, default: 'id' },
      modelsStorePath: { type: String, default: 'model.models' }
    }
  },
  config: {
    modelsStorePath: 'model.models'
  },
  AwesomeCrud: {
    props: {
      primaryKey: { type: String, default: 'id' },
      modelsStorePath: {
        type: String,
        default: 'model.models'
      },
      options: { detailPageMode: 'sidebar' }
    }
  },
  AwesomeTable: {
    props: {
      primaryKey: {
        type: String,
        default: 'id',
      },
      modelsStorePath: {
        type: String,
        default: 'model.models'
      }
    }
  },
  AwesomeForm: {
    props: {
      primaryKey: {
        type: String,
        default: 'id'
      },
      modelsStorePath: {
        type: String,
        default: 'model.models'
      }
    },

  }
});

const i18n = new VueI18n({
  locale: store.state.locale, // set locale
  messages: _.merge({ fr, en }, store.state.translations || {}), // set locale messages
  silentTranslationWarn: process.env.VUE_APP_NO_I18N_LOGS || process.env.NODE_ENV === 'production'
});

const io = sailsIOClient(socketIOClient);

io.sails.url = config.apiUrl.replace('/api', '');
io.sails.transports = [
  'websocket',
  'htmlfile',
  'xhr-polling',
  'jsonp-polling',
  'polling',
];

io.sails.headers = {
  authorization: `Bearer ${store.state.token}`,
};

io.socket.get(
  '/api/users',
  {
    token: store.state.token,
    access_token: store.state.token,
  },
  // eslint-disable-next-line
  (body, JWR) => {
    // body === JWR.body
  },
);

store.commit('io', io);

async function initVue(models) {
  const routes = await import('./routes');
  // configure router
  const router = new VueRouter({
    base: '/',
    mode: window.cordova ? 'hash' : 'history',
    routes: routes.default,
    linkActiveClass: 'nav-item active',
    parseQuery(query) {
      return qs.parse(query);
    },
    stringifyQuery(query) {
      const result = qs.stringify(query);
      return result ? `?${result}` : '';
    },
  });

  router.beforeEach((to, from, next) => {
    document.title = `${config.defaultTitle} - ${to.meta.title || to.name}`;
    next();
  });


  vInstance = new Vue({
    el: '#app',
    render: h => h(App),
    router,
    store,
    i18n,
    mixins: [mixin]
  });

  vInstance.$store.commit('extendedRoles');

  if (!isProduction) {
    window.App.vue = vInstance;
  }

  // REFRESH DATA
  if (localStorage.getItem(`${config.appName}_token`)) {
    vInstance.$store.dispatch('refreshListOfValues');
    vInstance.$store
      .dispatch('refreshUser')
      .then(res => {
        vInstance.$store.commit(
          'currentLocale',
          res.data.user.locale || config.defaultLocale,
        );
        if (
          vInstance.$route.name === 'Login'
          && vInstance.$store.state.user
          && vInstance.$store.state.user[config.primaryKey]
        ) {
          setTimeout(() => {
            vInstance.$router.push('/');
          }, 300);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}

store
  .dispatch('getModels')
  .catch((err) => console.warn(err)) // @todo we must warn the if the error was related to model getting
  .finally(initVue);
