// import _ from 'lodash';

export default [
  /*
    {
      identity: 'monitoring',
      icon: '',
      options: {
        mode: 'remote',
        filterInitiallyOn: true,
        actions: {
          view: true,
          create: false,
          edit: true,
          delete: true,
        },
        customTopRightActions: [],
        customInlineActions: [
          {
            name: 'monitoring-list-new-hebergement-type',
            label: '',
            class: 'btn-primary',
            title: 'Créer la correspondance',
            icon: 'fa fa-edit',
            canDisplay({ item }, context) {
              return (
                item.message && item.message.indexOf("Type d'hebergement") > -1
              );
            },
            action: ({
              item, action, location, props, id,
            }, context) => {
              let data = item.context;
              const targetData = {};
              if (_.isString(data)) {
                data = JSON.parse(data);
                targetData.idMarchand = data.id_marchand;
                targetData.valeur = data.hebergement_type;
              }
              context.$router.push({
                path: '/app/correspondances/create',
                query: { item: targetData },
              });
            },
          },
        ],
      },
    },
*/

  {
    identity: 'user',
    icon: '',

    actions: {
      create: '{{ userHasRole("ADMIN") }}',
      edit: '{{ userHasRole("ADMIN") }}',
      delete: '{{ userHasRole("ADMIN") }}',
    },
    options: {
      mode: 'local',
      customInlineActions: [
        {
          name: 'list-user-ride',
          label: '',
          class: 'btn-simple text-success',
          title: 'Voir les indicateurs',
          icon: 'fa fa-info',
          action({ item }, context) {
            const url = (`https://livodoc-front.dev.enyosolutions.com/app/reports?userId=${item.id}`);
            const win = window.open(url, '_blank');
            win.focus();
          },
        }],
    },

    nestedSchemas: [],
  },

];
